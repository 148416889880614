import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"


const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-16">
      <h2 className="text-xl text-white uppercase text-center">Capturing your moments</h2>
      <StaticImage src="../images/hero1.jpg" layout="fullWidth" alt="A dinosaur" />
    </div>
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
